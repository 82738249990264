import {getAxios} from "../store/api";

class SkinAnalysisService {
    submit(skinAnalysis) {
        return getAxios()
            .post("/skinAnalysis/submit", skinAnalysis)
            .then(response => {

                return response.data.account
            })
    }

    retrieveSchema() {
        return getAxios()
            .get("/skinAnalysis/schema")
            .then(response => {

                return response.data
            })
    }

    list(hide_processed, search) {
        return getAxios()
            .get(`/skinAnalysis?`, {
                params: {
                    hideProcessed: hide_processed,
                    search: search
                }
            })
            .then(response => {

                return response.data
            })
    }

    get(id) {
        return getAxios()
            .get("/skinAnalysis/" + id)
            .then(response => {
                return response.data
            })
    }

    markAsProcessed(id) {
        return getAxios()
            .post("/skinAnalysis/" + id + "/markAsProcessed")
            .then(response => {
                return response.data
            })
    }

    answerPerMail(id, answerText) {
        return getAxios()
            .post("/skinAnalysis/" + id + "/answerPerMail", {answerText: answerText})
            .then(response => {
                return response.data
            })
    }
}

export default new SkinAnalysisService();