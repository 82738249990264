<template>
  <div class="mt-2 pm-container card p-4">
    <div>
      <h5>Übersicht aller Hautberatungsanfragen</h5>
    </div>
    <hr>

    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">🔎</span>
      <input v-model.lazy="search" v-debounce="500" class="form-control" type="text"
             placeholder="Nach Name oder Email suchen">
    </div>
    <div class="input-group mb-3">
      <input v-model="hideProcessedRequests" class="form-check-input mx-1" type="checkbox">
      <label class="form-check-label">
        Bereits beantwortete ausblenden
      </label>
    </div>
    <div class="table-responsive-md">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Erstellt am</th>
          <th scope="col">Bereits geantwortet</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="a in skinAnalysis" v-bind:key="a.created">
          <th scope="row"></th>
          <td>{{ a.name }}</td>
          <td>{{ a.email }}</td>
          <td>{{ $filters.datetime(a.created) }}</td>
          <td>{{ a.processed ? "✅" : "Nein" }}</td>
          <td>
            <router-link class="btn btn-primary btn-sm" :to="`/hautberatungen/${a.id}/bearbeiten`">Anzeigen
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SkinAnalysisService from "../../service/skinAnalysis.service";

export default {
  name: "HautberatungVerwaltung",

  data() {
    return {
      search: '',
      skinAnalysis: [],
      hideProcessedRequests: true
    }
  },

  watch: {
    search() {
      this.doSearch()
    },

    hideProcessedRequests() {
      this.doSearch();
    }
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      SkinAnalysisService.list(this.hideProcessedRequests, this.search)
          .then(skinAnalysis => this.skinAnalysis = skinAnalysis);
    }
  }
}
</script>

<style scoped>

</style>